import { alpha } from '@mui/material/styles'
import { drawerWidth } from 'src/Theme.js'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'src/context/auth-context.js'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useRef } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useLayoutStore from 'src/store/layout.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import SettingsIcon from '@mui/icons-material/Settings'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { useNavigate, useLocation } from 'react-router-dom'
import TryIcon from '@mui/icons-material/Try'
import GroupAddIcon from '@mui/icons-material/GroupAdd'

import logo from 'src/assets/images/logo.png'

const getDrawerState = (state) => state.drawer
const setDrawerState = (state) => state.setDrawer

const MyDrawer = () => {
	let navigate = useNavigate()
	let location = useLocation()
	let current_path = location.pathname.split('/')[1]

	const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'))
	const changeDrawerState = useLayoutStore(setDrawerState)
	const drawer = useLayoutStore(getDrawerState)

	const { user } = useAuth()
	const firstName = user?.first_name || ''
	const lastName = user?.last_name || ''
	const fullName = `${firstName} ${lastName}`

	const drawerRef = useRef(null)
	const getParent = () => drawerRef.current?.parentElement

	const onNavigate = (path) => () => navigate(path, { from: '/' })

	return (
		<Box component="nav" sx={{ flexShrink: { sm: 0 } }} aria-label="navigation">
			<Drawer
				variant={matches === true ? 'temporary' : 'permanent'}
				open={drawer}
				onClose={() => {
					changeDrawerState(false)
				}}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
					container: getParent(),
				}}
				sx={{
					display: { xs: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				ref={drawerRef}
			>
				<Toolbar>
					<Box aria-label="logo" sx={{ lineHeight: 0 }}>
						<Link component={RouterLink} to="/" sx={{ textDecoration: 'none' }}>
							<Box sx={{ display: 'block', maxWidth: 208, maxHeight: 60, fontWeight: 600 }}>MedPlus Hospital</Box>
							{/* <Box component="img" src={logo} alt="logo" sx={{ display: 'block', maxWidth: 208, maxHeight: 60 }} /> */}
						</Link>
					</Box>
				</Toolbar>
				<Divider />
				<Box sx={{ m: 1.5, p: 0.5 }}>
					<Box
						sx={{
							bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
							borderRadius: 1,
							mb: 1,
						}}
					>
						<Typography variant="body2" sx={{ color: 'primary.main', p: 1 }}>
							Admin
						</Typography>
						<Typography variant="body2" sx={{ p: 1 }}>
							{fullName}
						</Typography>
					</Box>
					<Typography component="span" variant="body1" fontWeight="medium">
						Mangement
					</Typography>
					<List>
						<ListItem button selected={current_path === ''} onClick={onNavigate('/')}>
							<ListItemIcon>
								<EventAvailableIcon />
							</ListItemIcon>
							<ListItemText primary="Schedule" />
						</ListItem>
						<ListItem button selected={current_path === 'profile'} onClick={onNavigate('/profile')}>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Profile" />
						</ListItem>
						<ListItem button selected={current_path === 'case'} onClick={onNavigate('/case')}>
							<ListItemIcon>
								<RemoveCircleIcon />
							</ListItemIcon>
							<ListItemText primary="Case" />
						</ListItem>
						<ListItem button selected={current_path === 'onsite'} onClick={onNavigate('/onsite')}>
							<ListItemIcon>
								<TryIcon />
							</ListItemIcon>
							<ListItemText primary="On-Site Visit" />
						</ListItem>
						<ListItem button selected={current_path === 'registration'} onClick={onNavigate('/registration')}>
							<ListItemIcon>
								<GroupAddIcon />
							</ListItemIcon>
							<ListItemText primary="Registration" />
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</Box>
	)
}

export default MyDrawer
