export const formMedLabRegistration = {
	name: { th: 'ชื่อ', en: 'NAME', kh: 'ឈ្មោះ', mm: 'နာမည်' },
	surname: { th: 'ชื่อสกุล', en: 'SURNAME', kh: 'នាមត្រកូល', mm: 'မျိုးရိုးအမည်' },
	gender: { th: 'เพศ', en: 'GENDER', kh: '', mm: '' },
	dateOfBirth: { th: 'วันเกิด', en: 'DATE OF BIRTH', kh: 'ថ្ងៃកំណើត', mm: 'မွေးနေ့' },
	placeOfBirth: {
		th: 'สถานที่เกิด',
		en: 'PLACE OF BIRTH',
		kh: 'ទីកន្លែងកំណើត',
		mm: 'မွေးရပ်ဒေသ',
	},
	presentNationality: {
		th: 'สัญชาติปัจจุบัน',
		en: 'PRESENT NATIONALITY',
		kh: 'សញ្ជាតិបច្ចុប្បន្ន',
		mm: 'လက်ရှိနိုင်ငံသား',
	},
	originalNationality: {
		th: 'สัญชาติขณะเกิด',
		en: 'ORIGINAL NATIONALITY',
		kh: 'សញ្ជាតិពីកំណើត',
		mm: 'မွေးရာပါနိုင်ငံသား',
	},
	presentPassportNo: {
		th: 'เลขที่หนังสือเดินทางปัจจุบัน',
		en: 'PRESENT PASSPORT NO.',
		kh: 'លេខលិខិតឆ្លងដែនបច្ចុប្បន្ន',
		mm: 'လက်ရှိနိုင်ငံကူးလက်မှတ်နံပါတ်',
	},
	placeOfIssue: {
		th: 'ออกให้ที่',
		en: 'PLACE OF ISSUE',
		kh: 'ចេញនៅ',
		mm: 'တွင်ထုတ်ပြန်ခဲ့သည်။',
	},
	dateOfIssue: { th: 'เมื่อวันที่', en: 'DATE OF ISSUE', kh: 'នៅលើ', mm: '' },
	dateOfExpiry: {
		th: 'หมดอายุ',
		en: 'DATE OF EXPIRY',
		kh: 'ផុតកំណត់',
		mm: 'သက်တမ်းကုန်',
	},
	firstEntranceDateAndHasBeenStayedUntilNowsday: {
		th: 'วันเข้ามาครั้งแรกและอยู่ต่อถึงปัจจุบัน',
		en: 'FIRST ENTRANCE DATE  AND HAS BEEN STAYED UNTIL NOWSDAY',
		kh: 'កាលបរិច្ឆេទនៃការចូលដំបូង និងបន្តរហូតដល់បច្ចុប្បន្',
		mm: 'စတင်ဝင်ရောက်သည့်ရက်စွဲနှင့် လက်ရှိအချိန်အထိ ဆက်လက်နေထိုင်ပါ။',
	},
	presentAddressInThailand: {
		th: 'ที่อยู่ปัจจุบันในประเทศไทย',
		en: 'PRESENT ADDRESS IN THAILAND',
		kh: 'អាស័យដ្ឋានបច្ចុប្បន្ននៅប្រទេសថៃ',
		mm: 'ထိုင်းနိုင်ငံရဲ့ လက်ရှိလိပ်စာ',
	},
	telephone: {
		th: 'โทรศัพท์',
		en: 'TELEPHONE',
		kh: 'ទូរស័ព្ទ',
		mm: 'တယ်လီဖုန်းနံပါတ်',
	},
	fax: { th: 'แฟกซ์', en: 'FAX', kh: '', mm: '' },
	presentOccupation: {
		th: 'อาชีพ',
		en: 'PRESENT OCCUPATION',
		kh: 'មុខរបរ',
		mm: 'အလုပ်အကိုင်',
	},
	placeOfWork: {
		th: 'สถานที่ทำงานปัจจุบัน',
		en: 'PLACE OF WORK',
		kh: 'កន្លែងធ្វើការ',
		mm: 'အလုပ်နေရာ',
	},
	telephoneOfWork: {
		th: 'โทรศัพท์',
		en: 'TELEPHONE',
		kh: 'ទូរស័ព្ទ',
		mm: 'တယ်လီဖုန်းနံပါတ်',
	},
	faxOfWork: { th: 'แฟกซ์', en: 'FAX', kh: '', mm: '' },
	monthlyIncome: {
		th: 'รายได้ต่อเดือน',
		en: 'MONTHLY INCOME',
		kh: 'ចំណូលប្រចាំខែ',
		mm: 'လစဉ်ဝင်ငွေ',
	},
	father: {
		name: { th: 'ชื่อบิดา', en: "FATHER'S NAME", kh: 'ឈ្មោះឪពុក', mm: 'အဖအမည်' },
		nationality: { th: 'สัญชาติ', en: 'NATIONALITY', kh: 'សញ្ជាតិ', mm: 'နိုင်ငံသား' },
		presentAddress: {
			th: 'ที่อยู่ปัจจุบัน',
			en: 'PRESENT ADDRESS',
			kh: 'អាស័យដ្ឋានបច្ចុប្បន្',
			mm: 'လက်ရှိလိပ်စာ',
		},
	},

	mother: {
		name: {
			th: 'ชื่อมารดา',
			en: "MOTHER'S NAME",
			kh: 'ឈ្មោះម្តាយ',
			mm: 'မိခင်အမည်',
		},
		nationality: { th: 'สัญชาติ', en: 'NATIONALITY', kh: 'សញ្ជាតិ', mm: 'နိုင်ငံသား' },
		presentAddress: {
			th: 'ที่อยู่ปัจจุบัน',
			en: 'PRESENT ADDRESS',
			kh: 'អាស័យដ្ឋានបច្ចុប្បន្',
			mm: 'လက်ရှိလိပ်စာ',
		},
	},
	husbandOfWife: {
		name: {
			th: 'ชื่อสามี หรือ ภรรยา',
			en: 'NAME OF HUSBAND OF WIFE',
			kh: 'ឈ្មោះប្តីឬប្រពន្',
			mm: 'ခင်ပွန်း သို့မဟုတ် ဇနီးအမည်',
		},
		dateOfBirth: {
			th: 'วันเกิด',
			en: 'DATE OF BIRTH',
			kh: 'ថ្ងៃកំណើតរបស់ប្តីប្រពន្',
			mm: 'အိမ်ထောင်ဖက် မွေးနေ့',
		},
		placeOfBirth: {
			th: 'สถานที่เกิด',
			en: 'PLACE OF BIRTH',
			kh: 'ទីកន្លែងកំណើតរបស់ប្តីប្រពន្',
			mm: 'အိမ်ထောင်ဖက်၏ မွေးရပ်မြေ',
		},
		dateOfMarriage: {
			th: 'วันที่สมรส',
			en: 'DATE OF MARRIAGE',
			kh: 'កាលបរិច្ឆេទរៀបការ',
			mm: 'လက်ထပ်ရက်',
		},
		placeOfMarriage: {
			th: 'สถานที่สมรส',
			en: 'PLACE OF MARRIAGE',
			kh: 'កន្លែងរៀបការ',
			mm: 'အိမ်ထောင်ရေး',
		},
	},

	childrenPersons: {
		th: 'จำนวนบุตร (คน)',
		en: 'CHILDREN (persons)',
		kh: 'ចំនួនកុមារ',
		mm: 'ကလေးအရေအတွက်',
		name: { th: 'ชื่อ', en: 'NAME', kh: '', mm: '' },
		surname: { th: 'ชื่อสกุล', en: 'SURNAME', kh: '', mm: '' },
		gender: { th: 'เพศ', en: 'GENDER', kh: '', mm: '' },
		presentNationality: { th: 'สัญชาติปัจจุบัน', en: 'PRESENT NATIONALITY', kh: '', mm: '' },
		age: { th: 'อายุ (ปี)', en: 'AGE', kh: '', mm: '' },
		dateOfBirth: { th: 'วันเกิด', en: 'DATE OF BIRTH', kh: '', mm: '' },
		placeOfBirth: { th: 'สถานที่เกิด', en: 'PLACE OF BIRTH', kh: '', mm: '' },
	},
	educationOfApplicant: {
		th: 'คุณวุฒิของผู้ยื่นคำขอ',
		en: 'EDUCATION OF APPLICANT',
		kh: 'ការអប់រំរបស់អ្នក។',
		mm: 'သူမ၏ပညာရေ',
	},
	heightCm: { th: 'ความสูง​ (ซม.)', en: 'HEIGHT (cm.)', kh: '', mm: '' },
	weightKg: { th: 'น้ำหนัก (กก.)', en: 'WEIGHT (kg.)', kh: '', mm: '' },
	skinColour: { th: 'สีผิว', en: 'SKIN COLOUR', kh: '', mm: '' },
	hairColour: { th: 'สีผม', en: 'HAIR COLOUR', kh: '', mm: '' },
	scar: {
		th: 'ตำหนิ หรือ แผลเป็น',
		en: 'SCAR',
		kh: 'ស្នាមជាំឬស្នាម',
		mm: 'အမဲစက် သို့မဟုတ် အမာရွတ်',
	},
	underlyingDisease: {
		th: 'โรคประจำตัว',
		en: 'UNDERLYING DISEASE',
		kh: 'ជំងឺពីកំណើត',
		mm: 'မွေးရာပါရောဂါ',
	},
	healthCheckup: {
		th: 'ตรวจสุขภาพแรงงานต่างด้าวหรือยัง',
		en: 'HEALTH CHECKUP',
		kh: 'តើមានការពិនិត្យសុខភាពដល់ពលករបរទេសទេ?',
		mm: 'နိုင်ငံခြားအလုပ်သမားတွေအတွက် ကျန်းမာရေးစစ်ဆေးမှု ရှိပါသလား။',
	},
	hospitalClinic: {
		th: 'ไม่สบายไปหาหมอที่ไหน',
		en: 'HOSPITAL/CLINIC',
		kh: 'បើឈឺតើទៅពេទ្យឯណា?',
		mm: 'ဖျားရင် ဘယ်ဆရာဝန်ဆီသွားမလဲ။',
	},
	workPermit: {
		th: 'สถานะแรงงานต่างด้าว',
		en: 'WORK PERMIT',
		kh: 'ស្ថានភាពពលករបរទេស',
		mm: 'နိုင်ငံခြားအလုပ်သမား အဆင့်အတန်',
	},
}

export const genders = [
	{
		id: 1,
		gender: 'MALE',
	},
	{
		id: 2,
		gender: 'FEMALE',
	},
]

export const workPermit = [
	{
		id: 1,
		status: {
			en: 'MOU',
		},
	},
	{
		id: 2,
		// status: 'ทั่วไปถูกกฏหมาย - LEGAL - ច្បាប់ទូទៅ - ဘုံဥပဒေ',
		status: {
			th: 'ทั่วไปถูกกฏหมาย',
			en: 'LEGAL',
			kh: 'ច្បាប់ទូទៅ',
			mm: 'ဘုံဥပဒေ',
		},
	},
	{
		id: 3,
		// status: 'ทั่วไปผิดกฏหมาย - ILLEGAL - ខុសច្បាប់ជាទូទៅ - ယေဘုယျအားဖြင့် တရားမဝင်',
		status: {
			th: 'ทั่วไปผิดกฏหมาย',
			en: 'ILLEGAL',
			kh: 'ខុសច្បាប់ជាទូទៅ',
			mm: 'ယေဘုယျအားဖြင့် တရားမဝင်',
		},
	},
]

export const localLanguage = [
	{
		id: 1,
		language: 'th',
	},
	{
		id: 2,
		language: 'en',
	},
	{
		id: 3,
		language: 'kh',
	},
	{
		id: 4,
		language: 'mm',
	},
]

export const healthCheckup = [
	{
		id: 1,
		status: 'yes',
	},
	{
		id: 2,
		status: 'no',
	},
	{
		id: 2,
		status: 'not specified',
	},
]
