import Box from '@mui/material/Box'
import React, { useState } from 'react'
import Layout from 'src/components/layout'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import * as MedLabInput from 'src/api/medLabInput'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import moment from 'moment'
import times from 'lodash/times'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { reject } from 'lodash'
import { Button } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'

export default function Registration() {
	const [language, setLanguage] = useState('th')
	const localLanguage = MedLabInput.localLanguage
	const genders = MedLabInput.genders
	const formMedLabRegistration = MedLabInput.formMedLabRegistration
	const workPermit = MedLabInput.workPermit
	const healthCheckup = MedLabInput.healthCheckup

	const handleChange = (event) => {
		setLanguage(event.target.value)
	}
	const [dateOfBirth, setDateOfBirth] = useState(null)
	const [dateOfIssue, setDateOfIssue] = useState(null)
	const [dateOfExpiry, setDateOfExpiry] = useState(null)
	const [husbandOfWifeDateOfBirth, setHusbandOfWifeDateOfBirth] = useState(null)
	const [dateOfMarriage, setDateOfMarriage] = useState(null)
	const [childrenPersons, setChildrenPersons] = useState(0)
	const [childrens, setChildrens] = useState([])

	function translateLanguage(label) {
		if (label[`${language}`]) {
			return `${label[`${language}`]}`
		}
		return `${label[`en`]}`
	}

	function onChangeChildrenPersons(value) {
		setChildrenPersons(value)
	}

	function onChangeChildrenAge(index, age) {
		const temp = childrens.find((item) => item.id === index)
		const tempChildrens = [{ id: index, age: age, dateOfBirth: temp?.dateOfBirth ?? null }, ...reject(childrens, { id: index })]
		setChildrens(tempChildrens)
	}

	function onChangeChildrenBirth(index, birthDate) {
		const temp = childrens.find((item) => item.id === index)
		const tempChildrens = [{ id: index, age: temp?.age ?? 0, dateOfBirth: birthDate }, ...reject(childrens, { id: index })]
		setChildrens(tempChildrens)
	}

	function renderChildrenPersons() {
		const count = times(childrenPersons)
		return (
			<React.Fragment>
				<Divider sx={{ marginTop: 4 }}>
					<Chip color="primary" label={<Typography variant="h6">CHILDRENS INFORMATION</Typography>} />
				</Divider>
				{count.map((item, index) => {
					const age = childrens.find((item) => item.id === index)?.age ?? 0
					const childrensDateOfBirth = childrens.find((item) => item.id === index)?.dateOfBirth ?? null

					return (
						<Grid key={item} container spacing={2} marginTop={2}>
							<Grid item xs={6} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.childrenPersons.name)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons.name)}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.childrenPersons.surname)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons.surname)}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<Select
									fullWidth
									labelId="select-language"
									defaultValue={'MALE'}
									id="select-language"
									label={translateLanguage(formMedLabRegistration.childrenPersons.gender)}
								>
									{genders.map((item) => (
										<MenuItem key={item.id} value={item.gender}>
											{item.gender}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={6} md={2}>
								<TextField
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									value={age}
									onChange={(e) => onChangeChildrenAge(index, Number(e.target.value))}
									label={translateLanguage(formMedLabRegistration.childrenPersons.age)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons.age)}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<DesktopDatePicker
									label={translateLanguage(formMedLabRegistration.childrenPersons.dateOfBirth)}
									inputFormat="DD/MM/yyyy"
									value={childrensDateOfBirth}
									onChange={(event) => {
										const date = moment(event)
										onChangeChildrenBirth(index, date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.childrenPersons.presentNationality)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons.presentNationality)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label={translateLanguage(formMedLabRegistration.childrenPersons.placeOfBirth)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons.placeOfBirth)}
								/>
							</Grid>
						</Grid>
					)
				})}
			</React.Fragment>
		)
	}

	return (
		<Layout>
			<Box p={4}>
				<Box mb={5}>
					<Box fontSize={14}>Registration</Box>
					<Box fontSize={24}>Information Form</Box>
				</Box>
				<Box mb={3}>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<FormControl>
									<FormLabel id="demo-row-radio-buttons-group-label">Language</FormLabel>
									<RadioGroup onChange={handleChange} value={language} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
										{localLanguage.map((item) => (
											<FormControlLabel key={item.id} value={item.language} control={<Radio />} label={item.language.toUpperCase()} />
										))}
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12}>
								<Divider sx={{ marginTop: 4 }}>
									<Chip color="primary" label={<Typography variant="h6">PERSONAL INFORMATION</Typography>} />
								</Divider>
							</Grid>
							<Grid item xs={4} md={2}>
								<Select fullWidth labelId="select-language" defaultValue={'MALE'} id="select-language" label={translateLanguage(formMedLabRegistration.gender)}>
									{genders.map((item) => (
										<MenuItem key={item.id} value={item.gender}>
											{item.gender}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={4} md={5}>
								<TextField
									// label={translateLanguage(formMedLabRegistration.name)}
									label={translateLanguage(formMedLabRegistration.name)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.name)}
								/>
							</Grid>
							<Grid item xs={4} md={5}>
								<TextField
									label={translateLanguage(formMedLabRegistration.surname)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.surname)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={4} md={2}>
								<DesktopDatePicker
									label={translateLanguage(formMedLabRegistration.dateOfBirth)}
									inputFormat="DD/MM/yyyy"
									value={dateOfBirth}
									onChange={(event) => {
										const date = moment(event)
										setDateOfBirth(date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
							<Grid item xs={8} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.placeOfBirth)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.placeOfBirth)}
								/>
							</Grid>
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.presentNationality)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.presentNationality)}
								/>
							</Grid>
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.originalNationality)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.originalNationality)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={6} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.presentPassportNo)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.presentPassportNo)}
								/>
							</Grid>
							<Grid item xs={6} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.placeOfIssue)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.placeOfIssue)}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<DesktopDatePicker
									// label={ `${formMedLabRegistration.dateOfIssue)}
									label={translateLanguage(formMedLabRegistration.dateOfIssue)}
									inputFormat="DD/MM/yyyy"
									value={dateOfIssue}
									onChange={(event) => {
										const date = moment(event)
										setDateOfIssue(date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<DesktopDatePicker
									fullWidth
									label={translateLanguage(formMedLabRegistration.dateOfExpiry)}
									inputFormat="DD/MM/yyyy"
									value={dateOfExpiry}
									onChange={(event) => {
										const date = moment(event)
										setDateOfExpiry(date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={6} md={6}>
								<TextField
									label={translateLanguage(formMedLabRegistration.firstEntranceDateAndHasBeenStayedUntilNowsday)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.firstEntranceDateAndHasBeenStayedUntilNowsday)}
								/>
							</Grid>
							<Grid item xs={6} md={6}>
								<TextField
									label={translateLanguage(formMedLabRegistration.presentAddressInThailand)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.presentAddressInThailand)}
								/>
							</Grid>
							<Grid item xs={6} md={6} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.telephone)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.telephone)}
								/>
							</Grid>
							<Grid item xs={6} md={6} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.fax)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.fax)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={4} md={4} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.presentOccupation)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.presentOccupation)}
								/>
							</Grid>
							<Grid item xs={8} md={8} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.placeOfWork)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.placeOfWork)}
								/>
							</Grid>
							<Grid item xs={6} md={6} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.telephoneOfWork)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.telephoneOfWork)}
								/>
							</Grid>
							<Grid item xs={6} md={6} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.faxOfWork)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.faxOfWork)}
								/>
							</Grid>
							<Grid item xs={12} md={6} marginTop={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.monthlyIncome)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.monthlyIncome)}
								/>
							</Grid>
						</Grid>
						<Divider sx={{ marginTop: 4 }}>
							<Chip color="primary" label={<Typography variant="h6"> FAMILY MEMBER</Typography>} />
						</Divider>
						<Grid container spacing={2} marginTop={2}>
							{/* <Grid item xs={12}>
								<Typography variant="h6">FAMILY MEMBER</Typography>
							</Grid> */}
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.father.name)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.father.name)}
								/>
							</Grid>
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.father.nationality)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.father.nationality)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label={translateLanguage(formMedLabRegistration.father.presentAddress)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.father.presentAddress)}
								/>
							</Grid>
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.mother.name)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.mother.name)}
								/>
							</Grid>
							<Grid item xs={6} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.mother.nationality)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.mother.nationality)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label={translateLanguage(formMedLabRegistration.mother.presentAddress)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.mother.presentAddress)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={12} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.husbandOfWife.name)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.husbandOfWife.name)}
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<DesktopDatePicker
									label={translateLanguage(formMedLabRegistration.husbandOfWife.dateOfBirth)}
									inputFormat="DD/MM/yyyy"
									value={husbandOfWifeDateOfBirth}
									onChange={(event) => {
										const date = moment(event)
										setHusbandOfWifeDateOfBirth(date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<TextField
									label={translateLanguage(formMedLabRegistration.husbandOfWife.placeOfBirth)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.husbandOfWife.placeOfBirth)}
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<DesktopDatePicker
									label={translateLanguage(formMedLabRegistration.husbandOfWife.dateOfMarriage)}
									inputFormat="DD/MM/yyyy"
									value={dateOfMarriage}
									onChange={(event) => {
										const date = moment(event)
										setDateOfMarriage(date)
									}}
									renderInput={(params) => <TextField {...params} fullWidth />}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={6} md={4}>
								<TextField
									label={translateLanguage(formMedLabRegistration.educationOfApplicant)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.educationOfApplicant)}
								/>
							</Grid>
							<Grid item xs={6} md={2}>
								<TextField
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									value={childrenPersons}
									onChange={(e) => onChangeChildrenPersons(Number(e.target.value))}
									label={translateLanguage(formMedLabRegistration.childrenPersons)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.childrenPersons)}
								/>
							</Grid>
						</Grid>
						{childrenPersons > 0 && renderChildrenPersons()}
						<Divider sx={{ marginTop: 4 }}>
							<Chip color="primary" label={<Typography variant="h6"> PERSONAL APPEARANCE</Typography>} />
						</Divider>
						<Grid container spacing={2} marginTop={2}>
							{/* <Grid item xs={12}>
								<Typography variant="h6"> PERSONAL APPEARANCE</Typography>
							</Grid> */}
							<Grid item xs={4} md={4}>
								<TextField
									inputProps={{ inputMode: 'numeric', pattern: '/^[1-9][0-9]*$/' }}
									// defaultValue={0}
									// onChange={(e) => onChangeChildrenPersons(Number(e.target.value))}
									label={translateLanguage(formMedLabRegistration.heightCm)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.heightCm)}
								/>
							</Grid>
							<Grid item xs={2} md={2}>
								<TextField
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									// defaultValue={0}
									// onChange={(e) => onChangeChildrenPersons(Number(e.target.value))}
									label={translateLanguage(formMedLabRegistration.weightKg)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.weightKg)}
								/>
							</Grid>
							<Grid item xs={2} md={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.skinColour)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.skinColour)}
								/>
							</Grid>
							<Grid item xs={2} md={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.hairColour)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.hairColour)}
								/>
							</Grid>
							<Grid item xs={2} md={2}>
								<TextField
									label={translateLanguage(formMedLabRegistration.scar)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.scar)}
								/>
							</Grid>
						</Grid>
						<Divider sx={{ marginTop: 4 }}>
							<Chip color="primary" label={<Typography variant="h6">OTHER INFORMATION</Typography>} />
						</Divider>
						<Grid container spacing={2} marginTop={2}>
							{/* <Grid item xs={12}>
								<Typography variant="h6">OTHER INFORMATION</Typography>
							</Grid> */}
							<Grid item xs={3} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.underlyingDisease)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.underlyingDisease)}
								/>
							</Grid>
							<Grid item xs={3} md={3}>
								{/* <TextField
									label={translateLanguage(formMedLabRegistration.healthCheckup)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.healthCheckup)}
								/> */}
								<TextField
									required
									id="outlined-select-healthCheckup"
									select
									label={translateLanguage(formMedLabRegistration.healthCheckup)}
									defaultValue={1}
									fullWidth
								>
									{healthCheckup.map((item) => (
										<MenuItem key={item.id} value={item.id}>
											{item.status}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={3} md={3}>
								<TextField
									label={translateLanguage(formMedLabRegistration.hospitalClinic)}
									required
									fullWidth
									placeholder={translateLanguage(formMedLabRegistration.hospitalClinic)}
								/>
							</Grid>
							<Grid item xs={3} md={3}>
								<TextField
									required
									id="outlined-select-currency"
									select
									label={translateLanguage(formMedLabRegistration.workPermit)}
									defaultValue={1}
									fullWidth
								>
									{workPermit.map((item) => (
										<MenuItem key={item.id} value={item.id}>
											{translateLanguage(item.status)}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
						<Grid container spacing={2} marginTop={2}>
							<Grid item xs={12} textAlign="center">
								<Button variant="contained" size="large">
									SUBMIT
								</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Box>
		</Layout>
	)
}
